<template>
  <svg :width="size" :height="size" viewBox="0 0 18 17" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <title>编组</title>
    <g id="预付卡更改颜色" stroke="none" stroke-width="1" :fill="color" fill-rule="evenodd">
      <g id="开卡-6" transform="translate(-13.000000, -344.000000)" fill="#193D87" fill-rule="nonzero">
        <g id="编组" transform="translate(13.000000, 344.000000)">
          <path d="M10.4449783,5.51863269 C12.0700003,7.69726266 15.0068342,8.59452336 15.0068342,8.59452336 C14.7873695,4.82310706 12.5345571,2.98019181 8.96801885,2.96207937 C4.70142183,2.94032027 2.22301995,6.39324781 3.15749274,9.79195892 C3.15749274,9.79195892 10.3855437,7.07382413 10.4449783,5.51863269 Z" id="路径"></path>
          <path d="M15.6857786,12.2768281 C17.0142926,11.9354318 18,11.9567275 18,9.23716229 C18,7.92732486 17.2169343,6.80509923 16.1065553,6.33536457 C15.2885743,3.23771479 12.7417521,0.0883871549 8.91720732,0.00221708604 C8.79525562,-0.000502801565 8.6741904,-0.000966189824 8.55447505,0.00221708604 C4.84966578,0.0974131523 2.31096298,2.96203907 2.02533449,6.28457319 C0.847824625,6.72027903 -0.03859682,7.87828629 0.00129486495,9.23716229 C0.090607915,12.2885941 1.33750513,12.3370887 2.99746261,12.3710772 L2.99746261,6.46595947 C3.03237791,3.94140009 5.50572281,1.14114484 8.91720732,1.14114484 C12.3082826,1.14114484 14.184038,3.78044318 15.0110651,6.1249863 L15.0110651,12.1969843 C14.8442051,12.5878422 14.3975794,13.0271947 13.5818952,13.8147533 C12.5177139,14.8417022 10.984745,15.3744376 10.984745,15.3744376 C10.806542,15.4120728 10.6809436,15.6501334 10.7308284,15.8532385 C10.780693,16.0563638 10.9978408,16.1783356 11.1661111,16.099882 C11.1661111,16.099882 12.7834167,15.572123 13.9953987,14.4023296 C15.1951713,13.2439395 15.6816685,12.2881307 15.6857584,12.2768281 L15.6857786,12.2768281 Z" id="路径"></path>
          <path d="M5.4915794,10.3228205 C5.4915794,10.7397123 5.82953699,11.0776699 6.2464288,11.0776699 C6.66332061,11.0776699 7.0012782,10.7397123 7.0012782,10.3228205 C7.0012782,9.90592873 6.66332061,9.56797114 6.2464288,9.56797114 C5.82953699,9.56797114 5.4915794,9.90592873 5.4915794,10.3228205 Z M11.1724575,10.3228205 C11.1724575,10.7397068 11.5104106,11.0776599 11.9272969,11.0776599 C12.3441831,11.0776599 12.6821362,10.7397068 12.6821362,10.3228205 C12.6821362,9.90593429 12.3441831,9.56798121 11.9272969,9.56798121 C11.5104106,9.56798121 11.1724575,9.90593429 11.1724575,10.3228205 L11.1724575,10.3228205 Z" id="形状"></path>
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: "customer",
  props: {
    color: {type: String},
    size: {type: String}
  }
}
</script>

<style scoped>

</style>