<template>
  <svg :width="size" :height="size" viewBox="0 0 16 18" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <title>编组</title>
    <g id="预付卡更改颜色" stroke="none" stroke-width="1" :fill="color" fill-rule="evenodd">
      <g id="开卡-6" transform="translate(-13.000000, -234.000000)" fill="#193D87" fill-rule="nonzero">
        <g id="编组" transform="translate(13.000000, 234.000000)">
          <path d="M12.5096154,11.6923077 L9.84423077,6.80961538 C9.49038462,7.01730769 9.09807692,7.16730769 8.68076923,7.24423077 L11.1076923,11.6923077 L4.89423077,11.6923077 L7.32884615,7.24615385 C6.91153846,7.16923077 6.51730769,7.01923077 6.16346154,6.81346154 L3.49038462,11.6923077 L0,11.6923077 L0,17.2307692 L16,17.2307692 L16,11.6923077 L12.5096154,11.6923077 Z M14.7692308,16 L1.23076923,16 L1.23076923,12.9230769 L14.7692308,12.9230769 L14.7692308,16 Z" id="形状"></path>
          <path d="M11.6538462,3.65384615 C11.6538462,5 10.9269231,6.175 9.84423077,6.80961538 C9.49038462,7.01730769 9.09807692,7.16730769 8.68076923,7.24423077 C8.45961538,7.28653846 8.23269231,7.30769231 8.00192308,7.30769231 C7.77307692,7.30769231 7.54807692,7.28653846 7.33076923,7.24615385 C6.91346154,7.16923077 6.51923077,7.01923077 6.16538462,6.81346154 C5.07692308,6.18076923 4.34615385,5.00192308 4.34615385,3.65384615 C4.34615385,1.63461538 5.98269231,0 8,0 C10.0173077,0 11.6538462,1.63461538 11.6538462,3.65384615 Z" id="路径"></path>
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: "seal",
  props: {
    color: {type: String},
    size: {type: String}
  }
}
</script>

<style scoped>

</style>