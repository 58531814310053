<template>
  <svg :width="size" :height="size" viewBox="0 0 12 12" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <title>编组</title>
    <g id="预付卡更改颜色" stroke="none" stroke-width="1" :fill="color" fill-rule="evenodd">
      <g id="开卡-6" transform="translate(-47.000000, -374.000000)" fill="#737B8E" fill-rule="nonzero">
        <g id="编组" transform="translate(47.000000, 374.000000)">
          <path d="M3.17142857,4.71428572 C4.28571429,6.34285715 5.65714285,7.8 7.28571428,8.82857143 C7.71428571,8.48571428 8.22857142,8.05714285 8.74285713,7.71428571 C9.77142856,8.31428571 10.8,8.74285713 12,9.08571428 L10.8,12 C10.7142857,12 10.6285714,12 10.6285714,11.9142857 L10.6285714,12 C5.48571429,10.5428571 1.45714287,6.51428571 0,1.45714287 L0.0857142923,1.45714287 C0,1.37142858 0,1.28571428 0,1.20000001 L3,0 C3.34285716,1.19999999 3.77142858,2.22857142 4.37142857,3.25714287 C3.94285715,3.77142858 3.5142857,4.2 3.17142857,4.71428572 Z" id="路径"></path>
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: "tel",
  props: {
    size: {type: String},
    color: {type: String}
  }
}
</script>

<style scoped>

</style>