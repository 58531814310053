<template>
    <svg :width="size" :height="size" viewBox="0 0 11 18" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
      <title>箭头_右</title>
      <g id="预付卡更改颜色" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="开卡-1.1" transform="translate(-12.000000, -59.000000)" :fill="color" fill-rule="nonzero">
          <g id="箭头_右" transform="translate(17.057281, 68.000000) scale(-1, 1) translate(-17.057281, -68.000000) translate(12.000000, 59.000000)">
            <path d="M9.78846482,9.78846482 L1.90397544,17.6729542 C1.46791439,18.1090153 0.762633033,18.1090153 0.326571981,17.6729542 C-0.108225125,17.2381571 -0.10948907,16.5316118 0.326571981,16.0955508 L7.42235964,8.9997631 L0.326571981,1.90397544 C-0.108225125,1.46917833 -0.10948907,0.762633033 0.326571981,0.326571981 C0.762633033,-0.10948907 1.46791439,-0.108225125 1.90397544,0.326571981 L9.78846482,8.21106137 C10.2232619,8.64712242 10.2232619,9.35240377 9.78846482,9.78846482 L9.78846482,9.78846482 Z" id="路径"></path>
          </g>
        </g>
      </g>
    </svg>
</template>

<script>
export default {
  name: "left",
  props: {
    color: {type: String},
    size: {type: String}
  }
}
</script>

<style scoped>

</style>