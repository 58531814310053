<template>
  <svg :width="size" :height="size" viewBox="0 0 12 12" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <title>擦除</title>
    <g id="预付卡更改颜色" stroke="none" stroke-width="1" :fill="color" fill-rule="evenodd">
      <g id="开卡-3" transform="translate(-299.000000, -192.000000)" fill-rule="nonzero">
        <g id="擦除" transform="translate(299.000000, 192.000000)">
          <rect id="矩形" fill="#000000" opacity="0" x="2.92968684e-06" y="2.92968673e-06" width="11.9999971" height="11.9999971"></rect>
          <path d="M11.5000029,11.0000042 L0.50000293,11.0000042 C0.200006787,11.0000042 2.92968678e-06,11.1999963 2.92968678e-06,11.5000042 C2.92968678e-06,11.7999961 0.200006787,12.0000042 0.50000293,12.0000042 L11.5000029,12.0000042 C11.7999961,12.0000042 12.0000029,11.7999961 12.0000029,11.5000042 C12.0000029,11.1999963 11.7999961,11.0000042 11.5000029,11.0000042 L11.5000029,11.0000042 Z M2.04999852,9.84999662 C2.15000631,9.95000441 2.30000627,10 2.45000624,10 L6.45000135,10 C6.60000132,10 6.69999739,9.95000441 6.80000518,9.84999662 L11.85,4.80000176 C11.9499961,4.70000569 12,4.55000573 12,4.44999794 C12,4.34999015 11.9499961,4.2000019 11.85,4.10000583 L7.89999709,0.150002893 C7.70000496,-0.0500009644 7.40000503,-0.0500009644 7.20000117,0.150002893 L0.150002893,7.20000117 C-0.0500009644,7.40000503 -0.0500009644,7.70000496 0.150002893,7.89999709 L2.04999852,9.84999662 Z M7.55000499,1.20000264 L10.7499979,4.40000576 L7.89999709,7.25000507 L4.74999786,4.05000194 L7.55000499,1.20000264 Z M4.05000194,4.74999786 L7.25000507,7.95000099 L6.20000532,9.00000073 L2.6000062,9.00000073 L1.14999874,7.55000499 L4.05000194,4.74999786 Z" id="形状" fill="#EDA36B"></path>
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: "eraser",
  props: {
    color: {type: String},
    size: {type: String}
  }
}
</script>

<style scoped>

</style>