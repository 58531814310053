<template>
  <svg :width="size" :height="size" viewBox="0 0 16 16" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <title>016</title>
    <g id="预付卡更改颜色" stroke="none" stroke-width="1" :fill="color" fill-rule="evenodd">
      <g id="开卡-1.1" transform="translate(-16.000000, -453.000000)" fill-rule="nonzero">
        <g id="016" transform="translate(16.000000, 453.000000)">
          <rect id="矩形" fill="#000000" opacity="0" x="0" y="0" width="16" height="16"></rect>
          <path d="M13.8525,3.405 L2.1305,3.405 C1.438,3.405 0.875,3.9255 0.875,4.5655 L0.875,11.5275 C0.875,12.1675 1.438,12.688 2.1305,12.688 L13.8525,12.688 C14.5445,12.688 15.108,12.1675 15.108,11.5275 L15.108,4.5655 C15.108,3.9255 14.5435,3.405 13.8525,3.405 Z M1.7125,11.5275 L1.7125,4.714 L5.41,8.13 L5.406,8.13 L1.712,11.5435 L1.712,11.5275 L1.7125,11.5275 Z M2.317,4.179 L13.6655,4.179 L7.992,9.422 L2.317,4.179 Z M5.999,8.6755 L7.991,10.5165 L9.9925,8.667 L13.5085,11.9155 L2.494,11.9155 L5.999,8.6755 Z M10.5975,8.13 L10.5735,8.13 L14.271,4.714 L14.271,11.5245 L10.5975,8.13 Z" id="形状" stroke="#193D87" stroke-width="0.1" fill="#14367A"></path>
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: "mail",
  props: {
    color: {type: String},
    size: {type: String}
  }
}
</script>

<style scoped>

</style>