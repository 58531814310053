import qs from "qs";
import axios from "axios";
import store from "@/config/store"
import {showToast, Toast} from "vant";

const baseUrl = "/api/v1/vcard"

axios.interceptors.request.use(config => {
    return config;
}, error => {
    return Promise.reject(error);
})

axios.interceptors.response.use(response => {
    return response;
}, error => {
    return Promise.reject(error.response);
})

function errorState(response) {
    if (response && (response.status === 200 || response.status === 304 || response.status === 400)) {
        return response
    } else {
        //TODO: 弹窗提醒 网络异常
    }
}

const httpServer = (method, url, data = {}, option = {}) => {
    url = baseUrl + url
    let requestMethod = method.toUpperCase()
    let Public = {}
    let httpDefaultOpts = {
        method: requestMethod,
        url: url,
        timeout: 10*1000,
        params: Object.assign(Public, data),
        data: Object.assign(Public, {body:data}),
        headers: requestMethod == 'GET' ? {
            "Accept": "application/json",
            "Content-Type": "application/json; charset=utf-8",
            "Cache-Control": "no-cache",
            "Accept-Language": store.state.acceptLanguage,
            "x-lemon-termid": store.state.xLemonTermid,
            "x-lemon-osver": store.state.xLemonOsver,
            "x-lemon-appver": store.state.xLemonAppver,
            "x-lemon-secure": store.state.xLemonSecure,
            "x-lemon-termtyp": store.state.xLemonTermtyp,
            "x-lemon-downcnl": store.state.xLemonDowncnl,
            "x-lemon-locale": store.state.xLemonLocale,
            "x-lemon-usrtyp": store.state.xLemonUsrtyp,
            "x-auth-token": store.state.xAuthToken,
            "x-lemon-sign": store.state.xLemonSign
        } : {
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "application/json; charset=utf-8",
            "Cache-Control": "no-cache",
            "Accept-Language": store.state.acceptLanguage,
            "x-lemon-termid": store.state.xLemonTermid,
            "x-lemon-osver": store.state.xLemonOsver,
            "x-lemon-appver": store.state.xLemonAppver,
            "x-lemon-secure": store.state.xLemonSecure,
            "x-lemon-termtyp": store.state.xLemonTermtyp,
            "x-lemon-downcnl": store.state.xLemonDowncnl,
            "x-lemon-locale": store.state.xLemonLocale,
            "x-lemon-usrtyp": store.state.xLemonUsrtyp,
            "x-auth-token": store.state.xAuthToken,
            "x-lemon-sign": store.state.xLemonSign
        }
    }

    Object.assign(httpDefaultOpts, option)

    if (requestMethod == 'GET') {
        delete httpDefaultOpts.data
    } else {
        delete httpDefaultOpts.params
    }


    return new Promise((resolve, reject) => {
        axios(httpDefaultOpts).then(res => {
            resolve(res.data)
        }).catch(err => {
            console.log(err)
            errorState(err)
            reject(err)
        })
    })
}

export default httpServer