import axios from "axios";
import store from "@/config/store"

const baseUrl = "/api/v1/vcard"

function errorState(response) {
    if (response && (response.status === 200 || response.status === 304 || response.status === 400)) {
        return response
    } else {
        //TODO: 弹窗提醒 网络异常
    }
}

const lineServer = (method, url, data) => {
    let httpUrl = baseUrl+url
    let httpMethod = method.toUpperCase()
    if (httpMethod == 'GET'){
        httpUrl += "?"
        for (let key in data){
            httpUrl += key + "=" + data[key] + "&"
        }
        httpUrl = httpUrl.slice(0,-1)
        return new Promise((resolve, reject) => {
            axios.get(httpUrl, {
                headers: {
                    "Access-Control-Allow-Origin": "*",
                    "Content-Type": "application/json; charset=utf-8",
                    "Cache-Control": "no-cache",
                    "Accept-Language": store.state.acceptLanguage,
                    "x-lemon-termid": store.state.xLemonTermid,
                    "x-lemon-osver": store.state.xLemonOsver,
                    "x-lemon-appver": store.state.xLemonAppver,
                    "x-lemon-secure": store.state.xLemonSecure,
                    "x-lemon-termtyp": store.state.xLemonTermtyp,
                    "x-lemon-downcnl": store.state.xLemonDowncnl,
                    "x-lemon-locale": store.state.xLemonLocale,
                    "x-lemon-usrtyp": store.state.xLemonUsrtyp,
                    "x-auth-token": store.state.xAuthToken,
                    "x-lemon-sign": store.state.xLemonSign
                }
            }).then(res => {
                resolve(res.data)
            }).catch(err => {
                console.log(err)
                errorState(err)
                reject(err)
            })
        })
    } else {
        return new Promise((resolve, reject) => {
            axios.post(httpUrl, data, {
                headers: {
                    "Access-Control-Allow-Origin": "*",
                    "Content-Type": "application/json; charset=utf-8",
                    "Cache-Control": "no-cache",
                    "Accept-Language": store.state.acceptLanguage,
                    "x-lemon-termid": store.state.xLemonTermid,
                    "x-lemon-osver": store.state.xLemonOsver,
                    "x-lemon-appver": store.state.xLemonAppver,
                    "x-lemon-secure": store.state.xLemonSecure,
                    "x-lemon-termtyp": store.state.xLemonTermtyp,
                    "x-lemon-downcnl": store.state.xLemonDowncnl,
                    "x-lemon-locale": store.state.xLemonLocale,
                    "x-lemon-usrtyp": store.state.xLemonUsrtyp,
                    "x-auth-token": store.state.xAuthToken,
                    "x-lemon-sign": store.state.xLemonSign
                }
            }).then(res => {
                resolve(res.data)
            }).catch(err => {
                console.log(err)
                errorState(err)
                reject(err)
            })
        })
    }
}

export default lineServer