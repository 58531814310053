<template>
  <svg :width="size" :height="size" viewBox="0 0 60 60" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <title>编组</title>
    <g id="预付卡更改颜色" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g id="开卡-6" transform="translate(-158.000000, -65.000000)" fill-rule="nonzero">
        <g id="编组" transform="translate(158.000000, 65.000000)">
          <path d="M0,30 C0,13.4325 13.4325,0 30,0 C46.5675,0 60,13.4325 60,30 C60,46.5675 46.5675,60 30,60 C13.4325,60 0,46.5675 0,30 Z" id="路径" fill="#193D87" opacity="0.200000003"></path>
          <path d="M30,5.45454545 C43.5589091,5.45454545 54.5454545,16.4410909 54.5454545,30 C54.5454545,43.5589091 43.5589091,54.5454545 30,54.5454545 C16.4410909,54.5454545 5.45454545,43.5589091 5.45454545,30 C5.45454545,16.4410909 16.4410909,5.45454545 30,5.45454545 Z" id="路径" fill="#193D87"></path>
          <path d="M40.7376419,25.7637295 C40.5205718,25.2685118 40.1160497,24.8801034 39.6131687,24.6840483 C39.1102876,24.4879931 38.5502937,24.5003717 38.0565214,24.7184576 C37.8852276,24.7931199 37.7288289,24.8901809 37.5798778,25.0096405 L28.9034741,32.1622867 C28.1363757,32.796916 27.0266898,32.7819836 26.2744865,32.1249555 L23.1539602,29.4147149 C22.6045626,28.9392891 21.8737857,28.7313436 21.1571693,28.84652 C20.440553,28.9616964 19.8112432,29.3882385 19.4376294,30.0120131 C18.8641675,30.9751565 19.0131186,32.207084 19.8025596,33.0059704 L26.1329829,39.3970613 C26.5139544,39.7816733 27.0318701,39.9985926 27.5725407,40 C28.1132114,40.0013938 28.6322384,39.7871606 29.0151874,39.4045275 L29.022635,39.3970613 L40.3131311,28.0185303 C40.9090909,27.4286983 41.0727819,26.532751 40.7376419,25.7637295" id="路径" fill="#FECAA2"></path>
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: "success",
  props: {size: {type: String}}
}
</script>

<style scoped>

</style>