import {createRouter, createWebHashHistory, createWebHistory} from 'vue-router'

const routes = [
  {path: '/home', name: 'home', component: () => import('@/views/Home'),},
  {path: '/success', name: 'success', component: () => import('@/views/successPage'),},
  {path: '/term', name: 'term', component: () => import('@/views/term'),},
  {path: '/testPage', name: 'testPage', component: () => import('@/views/testPage'),},
    //外部条款页面
  {path: '/aggregation/policy_en.html', name: 'aggregationEn', component: () => import('@/views/other_terms/aggregation/aggregation_en')},   //首页en
  {path: '/aggregation/policy.html', name: 'aggregationZh', component: () => import('@/views/other_terms/aggregation/aggregation_zh')},    //首页zh
  {path: '/aggregation/policy_km.html', name: 'aggregationKm', component: () => import('@/views/other_terms/aggregation/aggregation_km')},   //首页km
  {path: '/web/protocol_en.html', name: 'signInEn', component: () => import('@/views/other_terms/user_sign_in/user_sign_in_en')},  //用户注册en
  {path: '/aggregation/protocol.html', name: 'signInZh', component: () => import('@/views/other_terms/user_sign_in/user_sign_in_zh')},   //用户注册zh
  {path: '/web/protocol_km.html', name: 'signInKm', component: () => import('@/views/other_terms/user_sign_in/user_sign_in_km')},   //用户注册km
  {path: '/aggregation/help.html', name: 'helpCenter', component: () => import('@/views/other_terms/helps/help_center')},   //帮助中心
  {path: '/aggregation/remitTopup.html', name: 'remitTopup', component: () => import('@/views/other_terms/helps/remit_topup')},   //帮助中心
  {path: '/aggregation/quickTopup.html', name: 'quickTopup', component: () => import('@/views/other_terms/helps/quick_topup')},   //帮助中心
  {path: '/aggregation/businesTopup.html', name: 'businessTopup', component: () => import('@/views/other_terms/helps/business_topup')},   //帮助中心
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
