import left from "@/icons/svg/left";
import sex from "@/icons/svg/sex";
import earth from "@/icons/svg/earth";
import right from "@/icons/svg/right";
import handbag from "@/icons/svg/handbag";
import passport from "@/icons/svg/passport";
import phone from "@/icons/svg/phone";
import user from "@/icons/svg/user";
import mail from "@/icons/svg/mail";
import eraser from "@/icons/svg/eraser";
import info from "@/icons/svg/info";
import down from "@/icons/svg/down";
import success from "@/icons/svg/success";
import seal from "@/icons/svg/seal";
import customer from "@/icons/svg/customer";
import tel from "@/icons/svg/tel";

export function svgConfig(app) {
    app
        .component('icon-left', left)
        .component('icon-right', right)
        .component('icon-down', down)
        .component('icon-earth', earth)
        .component('icon-handbag', handbag)
        .component('icon-passport', passport)
        .component('icon-phone', phone)
        .component('icon-user', user)
        .component('icon-sex', sex)
        .component('icon-mail', mail )
        .component('icon-eraser', eraser)
        .component('icon-info', info)
        .component('icon-success', success)
        .component('icon-seal', seal)
        .component('icon-customer', customer)
        .component('icon-tel', tel)
}