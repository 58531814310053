<template>
  <svg :width="size" :height="size" viewBox="0 0 12 7" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <title>箭头_右</title>
    <g id="预付卡更改颜色" stroke="none" stroke-width="1" :fill="color" fill-rule="evenodd">
      <g id="开卡-5" transform="translate(-207.000000, -686.000000)" fill="#737B8E" fill-rule="nonzero">
        <g id="编组-3" transform="translate(157.000000, 680.000000)">
          <g id="箭头_右" transform="translate(56.000000, 9.338837) rotate(90.000000) translate(-56.000000, -9.338837) translate(53.000000, 4.000000)">
            <path d="M5.80655743,5.80655743 L1.12944603,10.4836688 C0.870772827,10.742342 0.452397039,10.742342 0.193723837,10.4836688 C-0.0641995876,10.2257454 -0.064949365,9.80661984 0.193723837,9.54794664 L4.40297414,5.33869634 L0.193723837,1.12944603 C-0.0641995876,0.871522604 -0.064949365,0.452397039 0.193723837,0.193723837 C0.452397039,-0.064949365 0.870772827,-0.0641995876 1.12944603,0.193723837 L5.80655743,4.87083524 C6.06448086,5.12950844 6.06448086,5.54788423 5.80655743,5.80655743 L5.80655743,5.80655743 Z" id="路径"></path>
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: "down",
  props: {
    color: {type: String},
    size: {type: String}
  }
}
</script>

<style scoped>

</style>