import { createApp } from 'vue'
import App from './App.vue'
import router from './config/router'
import store from './config/store'
import 'amfe-flexible'
import {Image, RadioGroup, Radio, Button, Dialog, Popup, Picker, DatePicker, Loading, Stepper, Collapse, CollapseItem, Icon, Checkbox} from 'vant'
import http from './config/httpService'
import {svgConfig} from "@/config/svg";
import {showToast ,setToastDefaultOptions, showDialog} from "vant";
import 'vant/lib/index.css';
import i18n from './config/i18n';
import vueEsign from 'vue-esign';
import axios from "axios";
import VueAxios from 'vue-axios';
import AppLocal from "@/AppLocal";

const app = createApp(App)
svgConfig(app)
setToastDefaultOptions({position: 'bottom'})
axios.defaults.baseURL = '/mobile';
app.config.globalProperties.$axios = axios
app.config.globalProperties.$http = http
app.config.globalProperties.$router = router
app.config.globalProperties.$toast = showToast
app.config.globalProperties.$dialog = showDialog
app
    .use(store)
    .use(router)
    .use(i18n)
    .use(Image).use(RadioGroup).use(Radio).use(Button).use(Dialog).use(Popup).use(Picker).use(DatePicker).use(Loading).use(Stepper).use(Collapse).use(CollapseItem).use(Icon).use(Checkbox)
    .use(vueEsign)
    .use(VueAxios, axios)
    .mount('#app')
