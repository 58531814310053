<template>
  <svg :width="size" :height="size" viewBox="0 0 16 17" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <title>编组 2</title>
    <g id="预付卡更改颜色" stroke="none" stroke-width="1" :fill="color" fill-rule="evenodd">
      <g id="开卡-1.1" transform="translate(-12.000000, -320.000000)">
        <g id="编组-2" transform="translate(12.000000, 320.000000)">
          <rect id="矩形" fill="#D8D8D8" opacity="0" x="0" y="0" width="16" height="16"></rect>
          <g id="编组" transform="translate(3.035156, 1.164062)" fill="#14367A" fill-rule="nonzero">
            <path d="M6.96139309,9.13201944 L6.16968143,8.81209503 C7.24284557,6.16360691 6.25472462,2.80845032 6.24460043,2.77605292 L7.06263499,2.53104752 C7.10718143,2.67683585 8.14592333,6.20815335 6.96139309,9.13201944 Z M4.65307775,9.13201944 C3.46854752,6.20815335 4.50728942,2.67683585 4.55183585,2.52902268 L5.36987041,2.77402808 C5.35974622,2.80845032 4.37567495,6.16968143 5.44478942,8.81007019 L4.65307775,9.13201944 L4.65307775,9.13201944 Z" id="形状"></path>
            <path d="M8.38282937,7.54049676 C5.77078834,6.48353132 3.23974082,7.52632289 3.21544276,7.53847192 L2.88134449,6.75283477 C2.99676026,6.70423866 5.75458963,5.55615551 8.70477862,6.75080994 L8.38282937,7.54049676 Z M5.89430346,5.46098812 C4.21976242,5.46098812 2.99068575,4.9487041 2.91576674,4.9163067 L3.24986501,4.13066955 L3.08180346,4.52348812 L3.24784017,4.13066955 C3.27213823,4.14079374 5.80318575,5.18561015 8.41522678,4.12864471 L8.73515119,4.92035637 C7.73488121,5.32532397 6.75485961,5.46098812 5.89430346,5.46098812 Z" id="形状"></path>
            <path d="M9.53091253,15 L0,15 L0,0 L9.53091253,0 C10.7073434,0 11.665095,0.95774838 11.665095,2.13417927 L11.665095,12.8637959 C11.6671166,14.0422516 10.7093683,15 9.53091253,15 Z M0.854481641,14.1455184 L9.53091253,14.1455184 C10.237581,14.1455184 10.812635,13.5704644 10.812635,12.8637959 L10.812635,2.13417927 C10.812635,1.4275108 10.237581,0.852456803 9.53091253,0.852456803 L0.854481641,0.852456803 L0.854481641,14.1455184 Z" id="形状"></path>
            <polygon id="路径" points="2.49865011 10.7559395 9.09354752 10.7559395 9.09354752 11.6104212 2.49865011 11.6104212"></polygon>
            <path d="M5.80723542,9.39322354 C3.76012419,9.39322354 2.09368251,7.72678186 2.09368251,5.67967063 C2.09368251,3.6325594 3.76012419,1.96611771 5.80723542,1.96611771 C7.85434665,1.96611771 9.52078834,3.6325594 9.52078834,5.67967063 C9.52078834,7.72678186 7.85434665,9.39322354 5.80723542,9.39322354 Z M5.80723542,2.82262419 C4.22988661,2.82262419 2.94816415,4.10434665 2.94816415,5.68169546 C2.94816415,7.25904428 4.22988661,8.54076674 5.80723542,8.54076674 C7.38458423,8.54076674 8.6663067,7.25904428 8.6663067,5.68169546 C8.6663067,4.10434665 7.38458423,2.82262419 5.80723542,2.82262419 L5.80723542,2.82262419 Z" id="形状"></path>
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: "passport",
  props: {
    color: {type: String},
    size: {type: String}
  }
}
</script>

<style scoped>

</style>