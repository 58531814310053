import { createStore } from "vuex";

export default createStore({
    state: {
        cardType: '',
        cardPicUrl: '',
        userPicUrl: '',
        signPicUrl: '',
        cardAmount: '',
        userId: '',
        payType: '',
        minStep: '',
        activityId: '',
        activityName: '',
        discountAmount: 0,
        amount: '',
        cardQuantity: '',
        xLemonTermid: "",
        xLemonOsver: "",
        xLemonAppver: "",
        xLemonSecure: "",
        xLemonTermtyp: "",
        xLemonDowncnl: "",
        xLemonLocale: "",
        xLemonUsrtyp: "",
        xAuthToken: "",
        xLemonSign: "",
        reBack: "0",
        orderId: "",
        media: "1",
        single: false,
        acceptLanguage: "",
    },
    mutations: {
        setCardType(state, cardType) {
            state.cardType = cardType;
        },
        setCardPicUrl(state, cardPicUrl) {
            state.cardPicUrl = cardPicUrl;
        },
        setUserPicUrl(state, userPicUrl) {
            state.userPicUrl = userPicUrl;
        },
        setSignPicUrl(state, signPicUrl) {
            state.signPicUrl = signPicUrl;
        },
        setCardAmount(state, cardAmount) {
            state.cardAmount = cardAmount;
        },
        setUserId(state, userId) {
            state.userId = userId;
        },
        setPayType(state, payType) {
            state.payType = payType;
        },
        setMinStep(state, minStep) {
            state.minStep = minStep
        },
        setActivityId(state, activityId) {
            state.activityId = activityId
        },
        setActivityName(state, activityName) {
            state.activityName = activityName
        },
        setDiscountAmount(state, discountAmount) {
            state.discountAmount = discountAmount
        },
        setAmount(state, amount) {
            state.amount = amount;
        },
        setCardQuantity(state, cardQuantity) {
            state.cardQuantity = cardQuantity
        },
        setXLemonTermid(state, xLemonTermid) {
            state.xLemonTermid = xLemonTermid
        },
        setXLemonOsver(state, xLemonOsver) {
            state.xLemonOsver = xLemonOsver
        },
        setXLemonAppver(state, xLemonAppver) {
            state.xLemonAppver = xLemonAppver
        },
        setXLemonSecure(state, xLemonSecure) {
            state.xLemonSecure = xLemonSecure
        },
        setXLemonTermtyp(state, xLemonTermtyp) {
            state.xLemonTermtyp = xLemonTermtyp
        },
        setXLemonDowncnl(state, xLemonDowncnl) {
            state.xLemonDowncnl = xLemonDowncnl
        },
        setXLemonLocale(state, xLemonLocale) {
            state.xLemonLocale = xLemonLocale
        },
        setXLemonUsrtyp(state, xLemonUsrtyp) {
            state.xLemonUsrtyp = xLemonUsrtyp
        },
        setXAuthToken(state, xAuthToken) {
            state.xAuthToken = xAuthToken
        },
        setXLemonSign(state, xLemonSign) {
            state.xLemonSign = xLemonSign
        },
        setReBack(state, reBack) {
            state.reBack = reBack
        },
        setOrderId(state, orderId) {
            state.orderId = orderId
        },
        setMedia(state, media) {
            state.media = media
        },
        setSingle(state, single) {
            state.single = single
        },
        setAcceptLanguage(state, acceptLanguage) {
            state.acceptLanguage = acceptLanguage
        }
    },
    actions: {

    },
    modules: {

    }
})