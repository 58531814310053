<template>
  <router-view/>
</template>
<script type="text/javascript">
  import {useStore} from "vuex";
  import {useI18n} from "vue-i18n";
  import {useRouter} from "vue-router";
  import {getCurrentInstance} from "vue";
  import {closePage, getHeader, isEmpty, parseSearch} from "@/util/commonUtils";
  import {getActivity, getUserInfo} from "@/config/http";
  export default {
    name: 'App',
    setup() {
      const router = useRouter()
      const store  = useStore()
      const instance = getCurrentInstance()
      const { locale, t } = useI18n()
      const param = parseSearch(window.location.search)
      store.commit('setMinStep', 1);
      const resultHeader = (header) => {
        let headerObject = JSON.parse(header)
        store.commit("setXLemonTermid", headerObject['x-lemon-termid'])
        store.commit("setAcceptLanguage", headerObject['Accept-Language'])
        store.commit("setXLemonOsver", headerObject['x-lemon-osver'])
        store.commit("setXLemonAppver", headerObject['x-lemon-appver'])
        store.commit("setXLemonSecure", headerObject['x-lemon-secure'])
        store.commit("setXLemonTermtyp", headerObject['x-lemon-termtyp'])
        store.commit("setXLemonDowncnl", headerObject['x-lemon-downcnl'])
        store.commit("setXLemonLocale", headerObject['x-lemon-locale'])
        store.commit("setXLemonUsrtyp", headerObject['x-lemon-usrtyp'])
        store.commit("setXAuthToken", headerObject['x-auth-token'])
        store.commit("setXLemonSign", headerObject['x-lemon-sign'])
        router.push("/home")
      }
      const init = () => {
        if (window.location.pathname  == "/") {
          if (isEmpty(param.userid)) {
            store.commit('setUserId', param.userid)
            if (isEmpty(param.language)){
              locale.value = param.language
            }
            if (isEmpty(param.orderid)) {
              store.commit('setReBack', '1')
              store.commit('setOrderId', param.orderid)
            }
          }else {
            instance.proxy.$dialog({message: t("toast.nologin")}).then(() => {
              closePage()
            })
          }
        }else {
          router.push(window.location.pathname)
        }
        getHeader(instance.proxy)
      }
      window.resultHeader = resultHeader
      init()
      // router.push("/home")
    },
  }
</script>
<style lang="scss">
#app {
  font-family: PingFangSC-Regular, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  overflow: hidden;
  height: 100vh;
  letter-spacing: 0.02rem;
}
.other_terms {
  margin-block-start: 0;
  margin-block-end: 0;
  font-size: 0.35rem;
  text-align: left;
  color: #000!important;
}
.other_terms_box {
  overflow-y: auto!important;
  height: inherit;
  padding: 1rem 0.5rem;
}
</style>
