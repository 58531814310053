import axios from "axios";

const baseUrl = "/api/v1/vcard"

axios.interceptors.request.use(config => {
    return config;
}, error => {
    return Promise.reject(error);
})

axios.interceptors.response.use(response => {
    return response;
}, error => {
    return Promise.reject(error.response);
})

function errorState(response) {
    if (response && (response.status === 200 || response.status === 304 || response.status === 400)) {
        return response
    } else {
        //TODO: 弹窗提醒 网络异常
    }
}

const fileServer = (url, data, option = {}) => {
    url = baseUrl + url
    let formData = new FormData();
    formData.append("file", data);
    let httpDefaultOpts = {
        method: 'POST',
        url: url,
        timeout: 10*1000,
        data: formData,
        headers: {
            "Cache-Control": "no-cache",
            "Content-Type": "multipart/form-data",
            "x-lemon-termid": "aab4f4379da325ef7221ff4302b4e4482d375962",
            "x-lemon-osver": "16.4",
            "x-lemon-appver": "3.2.0",
            "x-lemon-secure": "NBGGRgBYJhjpN7F",
            "x-lemon-termtyp": "iOS",
            "x-lemon-downcnl": "Appstore",
            "x-lemon-locale": "en-US",
            "x-lemon-usrtyp": "USRI",
            "x-auth-token": "b141bcbb-adf2-4f14-9ac9-264a21cedbcf",
            "x-lemon-sign": "45067576c243bc4ec8b51c4a87a38f71"
        }
    }
    Object.assign(httpDefaultOpts, option)
    return new Promise((resolve, reject) => {
        axios(httpDefaultOpts).then(res => {
            resolve(res.data)
        }).catch(err => {
            console.log(err)
            errorState(err)
            reject(err)
        })
    })
}

export default fileServer