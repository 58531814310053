import httpServer from "@/config/httpService";
import fileServer from "@/config/fileService";
import lineServer from "@/config/lineService";

export function getTest(value1, value2, value3){
    return lineServer('get', '/test', {v1: value1, v2: value2, v3: value3})
}

export function sendSms(phone){
    return lineServer('post','/register/sms', {"body":phone})
}

export function submitInformation(userId, lastName, firstName, nationality, idNumber, idExpiryDate, gender, occupation, email, phoneCountry, phoneNumber, smsCode, smsToken, orderId){
    return httpServer('post', '/register', {userId: userId, lastName: lastName, firstName: firstName, nationality: nationality, idNumber: idNumber, idExpiryDate: idExpiryDate+" 00:00:00", gender: gender, occupation: occupation, email: email, phoneCountry: phoneCountry, phoneNumber: phoneNumber, smsCode: smsCode, smsToken: smsToken, orderId: orderId})
}

export function updateInformation(userId, idType, idAddress, signature, autoRecognition){
    return httpServer('post', '/update', {userId: userId, idType: idType, idAddress: idAddress, signature: signature, autoRecognition: autoRecognition})
}

export function uploadPicture(data) {
    return fileServer("/upload", data)
}

export function getActivity(){
    return httpServer('get', '/activity')
}

export function submitOrder(userId, amount, singleAmount, cardQuantity, paymentMethod, activityId){
    return httpServer('post', '/order', {userId: userId, amount: amount, singleAmount: singleAmount, cardQuantity: cardQuantity, paymentMethod: paymentMethod, activityId: activityId})
}

export function getUserInfo(userId) {
    return httpServer('get', "/user/"+userId)
}