<template>
  <svg :width="size" :height="size" viewBox="0 0 16 16" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <title>手机</title>
    <g id="预付卡更改颜色" stroke="none" stroke-width="1" :fill="color" fill-rule="evenodd">
      <g id="开卡-1.1" transform="translate(-17.000000, -498.000000)" fill-rule="nonzero">
        <g id="手机" transform="translate(17.000000, 498.000000)">
          <rect id="矩形" fill="#000000" opacity="0" x="0" y="0" width="16" height="16"></rect>
          <path d="M11.5601786,1 L3.43982143,1 C2.9386875,1 2.53125,1.4074375 2.53125,1.90857143 L2.53125,14.6285714 C2.53125,15.1297054 2.9386875,15.5371429 3.43982143,15.5371429 L11.5601786,15.5371429 C12.0613125,15.5371429 12.46875,15.1297054 12.46875,14.6285714 L12.46875,1.90857143 C12.46875,1.4074375 12.0613125,1 11.5601786,1 Z M11.5601786,14.1742857 C11.5601786,14.4241429 11.35575,14.6285714 11.1058929,14.6285714 L3.89410714,14.6285714 C3.64425,14.6285714 3.43982143,14.4241429 3.43982143,14.1742857 L3.43982143,11.9851964 L11.5601786,11.9851964 L11.5601786,14.1742857 Z M3.43982143,11.076625 L3.43982143,2.36285714 C3.43982143,2.113 3.64425,1.90857143 3.89410714,1.90857143 L11.1058929,1.90857143 C11.35575,1.90857143 11.5601786,2.113 11.5601786,2.36285714 L11.5601786,11.076625 L3.43982143,11.076625 Z M6.71919643,13.342375 C6.71919643,13.7736009 7.06877409,14.1231786 7.5,14.1231786 C7.93122591,14.1231786 8.28080357,13.7736009 8.28080357,13.342375 C8.28080357,12.9111491 7.93122591,12.5615714 7.5,12.5615714 C7.06877409,12.5615714 6.71919643,12.9111491 6.71919643,13.342375 Z" id="形状" fill="#14367A"></path>
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: "phone",
  props: {
    color: {type: String},
    size: {type: String}
  }
}
</script>

<style scoped>

</style>