import { createI18n} from "vue-i18n";
import en from "@/lang/en"
import zh from "@/lang/zh"
import km from "@/lang/km"

const language = (navigator.language || 'en' ).toLocaleLowerCase();
const i18n = createI18n({
    legacy: false,
    locale: language || 'en',
    fallbackLocale: 'en',
    messages: {
        en,zh,km
    }
})

export default i18n;