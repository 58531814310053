import {getCurrentInstance} from "vue";

function mathWidth(windowWidth, proportion) {
    let widthTemp = windowWidth/100
    return Math.floor(widthTemp*proportion)
}

function mathHeight(windowHeight, proportion) {
    let heightTemp = windowHeight/100
    return Math.floor(heightTemp*proportion)
}

function phone() {
    let u = navigator.userAgent;
    if (u.indexOf('Android') > -1 || u.indexOf('Adr') > -1){
        return 1;
    }else if (!!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)){
        return 2;
    }else {
        return 0;
    }
}

function closePage(proxy) {
    switch (phone()){
        case 1:
            window.android.closePage()
            break;
        case 2:
            window.webkit.messageHandlers.closePage.postMessage({title: "message"})
            break;
        default:
            proxy.$toast("error")
    }
}

function sendCodeFunc(proxy, phoneNum) {
    switch (phone()){
        case 1:
            window.android.sendCode(JSON.stringify({phoneNum: phoneNum}))
            break;
        case 2:
            window.webkit.messageHandlers.sendCode.postMessage({title: "message"})
            break;
        default:
            proxy.$toast("error")
    }
}

function orderPage(proxy) {
    switch (phone()){
        case 1:
            window.android.orderPage()
            break;
        case 2:
            window.webkit.messageHandlers.orderPage.postMessage({title: "message"})
            break;
        default:
            proxy.$toast("error")
    }
}

function checkout(that, userInfoId, amount, singleAmount, cardQuantity, paymentMethod, ccy, activityId) {
    let result = ""
    switch (phone()){
        case 1:
            result = window.android.checkout(JSON.stringify({userInfoId: userInfoId, amount: amount, singleAmount: singleAmount, cardQuantity: cardQuantity, paymentMethod: paymentMethod, ccy: ccy, activityId: activityId}))
            break;
        case 2:
            result = window.webkit.messageHandlers.checkout.postMessage({userInfoId: userInfoId, amount: amount, singleAmount: singleAmount, cardQuantity: cardQuantity, paymentMethod: paymentMethod, ccy: ccy, activityId: activityId})
            break;
        default:
            that.$toast("error")
    }
    return result
}

function getHeader(proxy){
    switch (phone()){
        case 1:
            window.android.getHeader()
            break;
        case 2:
            window.webkit.messageHandlers.getHeader.postMessage({title: "message"})
            break;
        default:
            proxy.$toast("error")
    }
}

function base64ToFile(base64, filename) {
    const arr = base64.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
    }
    const blob = new Blob([u8arr], { type: mime });
    return new File([blob], filename, { type: mime });
}

function parseSearch(search) {
    let queryParams = new URLSearchParams(search)
    let params = {};
    for (const [key, value] of queryParams) {
        params[key] = value;
    }
    return params
}

function isEmpty(value) {
    return value != undefined && value != null && value != '' && value != 'null';
}

function getFileForView() {
    window.webkit.messageHandlers.selectedImage.postMessage({})
}

export {
    mathWidth,
    mathHeight,
    closePage,
    orderPage,
    checkout,
    getHeader,
    base64ToFile,
    parseSearch,
    isEmpty,
    phone,
    getFileForView,
}