<template>
  <svg :width="size" :height="size" viewBox="0 0 16 16" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <title>编组</title>
    <g id="预付卡更改颜色" stroke="none" stroke-width="1" :fill="color" fill-rule="evenodd">
      <g id="开卡-1.1" transform="translate(-12.000000, -192.000000)">
        <g id="编组" transform="translate(12.000000, 192.000000)">
          <rect id="矩形" fill="#D8D8D8" opacity="0" x="0" y="0" width="16" height="16"></rect>
          <path d="M7.5340137,8.48035375 C5.47064095,8.48035375 3.79383682,6.80103943 3.79383682,4.74017688 C3.79383682,2.67931432 5.47064095,1 7.5340137,1 C9.59738645,1 11.2741906,2.67931432 11.2741906,4.74017688 C11.2741906,6.80103943 9.59738645,8.48035375 7.5340137,8.48035375 L7.5340137,8.48035375 Z M7.5340137,1.84342244 C5.93753552,1.84342244 4.63725925,3.14369869 4.63725925,4.74017688 C4.63725925,6.33665506 5.9375355,7.63693132 7.5340137,7.63693132 C9.1304919,7.63693132 10.4307681,6.33665506 10.4307681,4.74017688 C10.4307681,3.14369869 9.13049188,1.84342244 7.5340137,1.84342244 Z M12.8731789,15.021898 L2.19484845,15.021898 C1.53466957,15.021898 1,14.4847182 1,13.8270496 L1,13.0187697 L1.02510186,12.9484845 C1.82836131,10.7219497 4.5042194,9.16563448 7.5340137,9.16563448 C10.563808,9.16563448 13.2396661,10.7219497 14.0429255,12.9484845 L14.0680274,13.0187697 L14.0680274,13.8270496 C14.0680274,14.4872284 13.5308476,15.021898 12.8731789,15.021898 Z M1.84593262,13.1693809 L1.84593262,13.8270496 C1.84593262,14.0203339 2.00407433,14.1784756 2.19735864,14.1784756 L12.8731789,14.1784756 C13.0664632,14.1784756 13.224605,14.0203339 13.224605,13.8270496 L13.224605,13.1668707 C12.509202,11.3043128 10.1822597,10.0115671 7.5340137,10.0115671 C4.88576766,10.0115671 2.55882539,11.3043128 1.84593262,13.1693809 Z" id="形状" fill="#14367A" fill-rule="nonzero"></path>
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: "user",
  props: {
    color: {type: String},
    size: {type: String}
  }
}
</script>

<style scoped>

</style>