<template>
  <svg :width="size" :height="size" viewBox="0 0 16 16" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <title>说明</title>
    <g id="预付卡更改颜色" stroke="none" stroke-width="1" :fill="color" fill-rule="evenodd">
      <g id="开卡-4.0" transform="translate(-220.000000, -192.000000)" fill-rule="nonzero">
        <g id="说明" transform="translate(220.000000, 192.000000)">
          <rect id="矩形" fill="#000000" opacity="0" x="0" y="0" width="16" height="16"></rect>
          <path d="M7.58758789,8.46258789 C7.69684699,8.35301883 7.84526505,8.2915064 8,8.29166211 C8.96649831,8.29166211 9.75,7.50816042 9.75,6.54166211 C9.75,5.5751638 8.96649831,4.79166211 8,4.79166211 C7.03350169,4.79166211 6.25,5.5751638 6.25,6.54166211 C6.24844566,6.86272465 5.98773525,7.12217595 5.66666895,7.12217595 C5.34560264,7.12217595 5.08489224,6.86272465 5.08333789,6.54166211 C5.08365649,5.00684272 6.2734057,3.7348736 7.8047834,3.63214585 C9.3361611,3.5294181 10.6851383,4.6310846 10.8904489,6.15210999 C11.0957594,7.67313538 10.0871471,9.09303321 8.58333789,9.4 L8.58333789,10.0416621 C8.58333789,10.3638307 8.32216862,10.625 8,10.625 C7.67783138,10.625 7.41666211,10.3638307 7.41666211,10.0416621 L7.41666211,8.875 C7.41666211,8.714 7.482,8.56816211 7.58758789,8.46258789 L7.58758789,8.46258789 Z M8,15 C4.13425,15 1,11.86575 1,8 C1,4.13425 4.13425,1 8,1 C11.86575,1 15,4.13425 15,8 C15,11.86575 11.86575,15 8,15 Z M8,13.8333379 C11.22175,13.8333379 13.8333379,11.22175 13.8333379,8 C13.8333379,4.77825 11.22175,2.16666211 8,2.16666211 C4.77825,2.16666211 2.16666211,4.77825 2.16666211,8 C2.16666211,11.22175 4.77825,13.8333379 8,13.8333379 Z M8,12.3750069 C7.79092312,12.3760122 7.59729357,12.2650518 7.49246185,12.0841527 C7.38763013,11.9032537 7.38763013,11.6800842 7.49246185,11.4991851 C7.59729357,11.3182861 7.79092312,11.2073257 8,11.208331 C8.32106254,11.2098922 8.58051384,11.4706026 8.58051384,11.7916689 C8.58051384,12.1127353 8.32106254,12.3734457 8,12.3750069 Z" id="形状" fill="#737B8E"></path>
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: "info",
  props: {
    color: {type: String},
    size: {type: String}
  }
}
</script>

<style scoped>

</style>